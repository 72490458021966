import Script from "next/script";
import { useEffect } from "react";
import TagManager from "react-gtm-module";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "../styles/globals.css";

export default function CanastaAhorro({ Component, pageProps }: any) {
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout || ((page: any) => page);

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_STAGE === "production")
      TagManager.initialize({ gtmId: "GTM-TNLQC5VG" });
  }, []);

  return getLayout(
    <>
      <Script
        strategy="beforeInteractive"
        src="https://sdk.mercadopago.com/js/v2"
      />
      <ToastContainer hideProgressBar position="top-right" />
      <Component {...pageProps} />
    </>,
  );
}
